.switchContainer {
  display: flex;
  justify-content: space-between;
  padding: 20px 32px;
}

.on {
  border: 2.5px outset var(--window-head-bg);
  background: var(--window-head-bg);
  color: white;
  border-radius: 10px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 49.5%;
  height: 45px;
  font-weight: 700;
  font-size: 16px;
}

.off {
  border: 2.5px inset var(--window-head-bg);
  background: white;
  color: var(--window-head-bg);
  border-radius: 10px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 49.5%;
  height: 45px;
  font-weight: 700;
  font-size: 16px;
}

#active {
  border: 2.5px outset var(--window-head-bg);
  background: var(--window-head-bg);
  color: white;
}

#adjective {
  border: 2.5px inset var(--window-head-bg);
  background: white;
  color: var(--window-head-bg);
}
