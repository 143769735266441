.main__decor {
  position: absolute;
  top: 100px;
  right: 0;

  :global(._rtl) & {
    right: initial;
    left: 0;
    transform: scaleX(-1);
  }
}

.languageWrapper {
  color: var(--light-text);
}
