:root{
  --header-bg: linear-gradient(180deg, rgba(74,108,176,1) 0%, rgba(49,81,144,1) 100%);
  --main-bg: #FFFFFF;
  --details-bg:#F6FAFF;
  --window-head-bg: #4A6CB0;
  --window-body-bg: rgba(140, 168, 224, 0.33);
  --date-text: rgba(140, 168, 224, 0.65);
  --reminder--text:#909597;
  --progress-bar-line: #C7D4ED;
  --progress-bar-progress: #8CA8E0;
  --main-text: #263238;
  --light-text: #FFFFFF;
  --accent-text: #F3C26A;
  --second-color: #4A6CB0;
  --placed-quote_dark-bg:#5578BC;
  --third-color:#2DAAB2;
  --status-bg-one: #2BAA39;
  --status-bg-two: #2DAAB2;
  --input-bg: #EDF3FF;
  --input-text: #7e8283;
  --error: rgb(255 0 35 / 84%);
}
body{
  font-family: 'Open Sans', sans-serif;
}
.wrapper{
  display: flex;
  flex-direction: column;
  min-height: 100%;
  overflow: hidden;
}
.main{
flex: 1 1 auto;
}
.container{
  max-width: 1200px;
  margin: 0 auto;
}

.header{
  background:var(--header-bg);
  position: relative;
}
.header__decor{
  position: absolute;
  bottom: 0;
  right: 0;
}
._rtl .header__decor {
  right: initial;
  left: 0;
  transform: scaleX(-1);
}
.header__body{
  padding: 40px 90px 102px 47px;
}
._rtl .header__body{
  padding: 40px 47px 102px 90px;
}
.header__quote{
  color: var(--light-text);
  font-weight: 300;
  font-size: 12px;
  line-height: 108.6%;
  margin-bottom: 26px;
}
.header__welcome{
  color: var(--accent-text);
  font-weight: 700;
  font-size: 25px;
  line-height: 108.6%;
  margin-bottom: 10px;
}
.header__text{
  color: var(--light-text);
  font-weight: 400;
  font-size: 14px;
  line-height: 108.6%;
}
.header__phone{
  color: var(--light-text);
  font-weight: 400;
  font-size: 14px;
  line-height: 108.6%;
  margin-top: 1em;
}
.header__phone > a{
  color: var(--light-text);
}

main{
  margin-top: -71px;
  z-index: 1;
  background: var(--main-bg);
  border-radius: 35px 35px 0 0;
  -webkit-border-radius: 35px 35px 0 0;
  -moz-border-radius: 35px 35px 0 0;
  -ms-border-radius: 35px 35px 0 0;
  -o-border-radius: 35px 35px 0 0;
}
.progress-bar{
  padding: 20px 52px 20px;
}
.progress-bar__title{
  color: var(--third-color);
  font-weight: 300;
  font-size: 14px;
  line-height: 136.4%;
  margin-bottom: 6px;
}
.progress-bar__title > span{
  font-weight: 600;
}
.progress-bar__line{
  width: 100%;
  height: 6px;
  background-color: var(--progress-bar-line);
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
}
.progress-bar__line > span{
  display: block;
  height: 6px;
  background-color: var(--accent-text);
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
}
.to-do__body{
  padding: 20px 32px;
  margin: 0 auto;
}
.to-do__columns{
  display: flex;
  gap: 13px;
  justify-content: center;
}
.to-do__column_left{
  flex: 0 0 55%
}
.to-do__column_right{
  flex: 0 0 24.8%;
}
.to-do__name{
  color: var(--second-color);
  font-weight: 700;
  font-size: 11px;
  line-height: 136.4%;
  margin-bottom: 10px;
}
.to-do__day, .to-do__week{
  background-color: var(--window-body-bg);
  border-radius: 13px;
  -webkit-border-radius: 13px;
  -moz-border-radius: 13px;
  -ms-border-radius: 13px;
  -o-border-radius: 13px;
  margin-bottom: 20px;
}
.to-do__day:last-child, .to-do__week:last-child{
  margin-bottom: 0;
}
.to-do__name-column{
  background-color: var(--window-head-bg);
  color: var(--light-text);
  font-weight: 700;
  font-size: 16px;
  line-height: 136.4%;
  padding: 20px 27px;
  border-radius: 13px 13px 0 0 ;
  -webkit-border-radius: 13px 13px 0 0 ;
  -moz-border-radius: 13px 13px 0 0 ;
  -ms-border-radius: 13px 13px 0 0 ;
  -o-border-radius: 13px 13px 0 0 ;
  white-space: nowrap;
}
.to-do__tasks{
  padding: 20px 10px;

}
.task-week__clip{
  position: absolute;
  z-index: 2;
  top: 0;
  right: 0;
}
.task-week__status{
  color: var(--light-text);
  font-weight: 700;
  font-size: 9px;
  line-height: 136.4%;
  padding: 2px 4px;
  margin-bottom: 15px;
  background-color: var(--status-bg-one);
  border-radius: 13px;
  -webkit-border-radius: 13px;
  -moz-border-radius: 13px;
  -ms-border-radius: 13px;
  -o-border-radius: 13px;
  display: inline-block;
}
.task-week__status{
  padding: 1px 2px;
  font-size: 8;
  margin-bottom: 7px;
}

.task-week__till{
  color: var(--main-text);
  font-weight: 700;
  font-size: 13px;
  line-height: 136.4%;
  text-decoration:underline;
  margin-bottom: 7px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.task-week__till{
  font-size: 10px;
}
.task-week__text{
  color: var(--main-text);
  font-weight: 400;
  font-size: 13px;
  line-height: 136.4%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.task-week__text{
  font-size: 10px;
}


@media (min-width: 720px) {
  .to-do__columns{
      gap: 20px;
  }
  .to-do__name{
      font-size: 14px;
      line-height: 136.4%;
      margin-bottom: 10px;
  }
  .to-do__tasks{
      display: flex;
      flex-wrap: wrap;
  }
  .to-do__task{
      width: 146px;
      height: 150px;
      margin: 0;

  }
  .to-do__week > .to-do__tasks{
      display: block;
  }
  .to-do__task-week{
      width: 146px;
      height: 150px;
      padding: 5px 13px ;
      margin: 0 auto;
      margin-bottom: 15px;
  }
  .task-week__clip{
      position: absolute;
      z-index: 2;
      top: 5px;
      right: 20px;
  }
  .task-week__status{
      font-size: 9px;
      padding: 2px 4px;
      margin-bottom: 15px;
  }
  .task-week__till{
      font-size: 13px;
      margin-bottom: 7px;
  }
  .task-week__text{
      font-size: 13px;
  }
  .task-week__till, .task-week__text{
      padding-left: 10px;
  }
}
@media (min-width: 900px) {
  .to-do__column_left{
      flex: 1 1  70%;

  }
  .to-do__column_right{
      flex: 1 1  20%;
  }
  .to-do__task{
      margin-bottom: 0;
  }
  .to-do__task-week{
      min-width: 100px;
      min-height: 100px;
      padding: 10px;
      margin-bottom: 0;
  }
}

















/* modal windows*/
/*================================================================================== */
/* .modal__body{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  flex-wrap: wrap;
  height: 100vh;
}
.modal__window{
  background: var(--main-bg);
  border-radius: 65px;
  -webkit-border-radius: 65px;
  -moz-border-radius: 65px;
  -ms-border-radius: 65px;
  -o-border-radius: 65px;
  border: 2px solid var(--second-color);
  overflow: hidden;

} */
/* .modal__free-task{
  padding: 27px 17px 39px;
  max-width: 351px;

} */
/* .free-task__name, .free-task__when{
  margin-left: 13px;
}
.free-task__name{
  color: var(--accent-text);
  font-weight: 600;
  font-size: 14px;
  line-height: 136.4%;
  margin-bottom: 14px;
}
.free-task__when{
  color: var(--date-text);
  font-size: 16px;
  line-height: 136.4%;
  margin-bottom: 17px;
}
.free-task__when span{
  color: var(--third-color);
  font-weight: 600;
}
.free-task__details{
  background: var(--details-bg);
  padding: 34px 15px 56px;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  margin-bottom: 27px;
} */

.modal__title{
  color: var(--second-color);
  font-weight: 700;
  font-size: 22px;
  line-height: 136.4%;
}
.free-task__title{
  margin-bottom: 17px;
}
.free-task__text{
  color: var(--date-text);
  font-size: 16px;
  line-height: 136.4%;
  overflow: hidden;
  overflow-wrap: break-word;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.model__box-btns{
  display: flex;
  gap: 0 8px;
  justify-content: center;
}
.modal__btn{
  color: var(--light-text);
  font-weight: 700;
  font-size: 13px;
  line-height: 136.4%;
  background-color: var(--status-bg-two);
  padding: 7px 19px;
  border-radius: 27px;
  -webkit-border-radius: 27px;
  -moz-border-radius: 27px;
  -ms-border-radius: 27px;
  -o-border-radius: 27px;
  text-align: center;
  display: block;
}
.modal__btn_transparent{
  color: var(--status-bg-two);
  background: transparent;
  border: 1px solid var(--status-bg-two);
}





/* .modal__window-done */
/* =========================== */
/* .modal__window-done{
  max-width: 296px;
  background-color: var(--main-bg);
  padding: 37px;
  position: relative;
  overflow: hidden;
} */
/* .window-done__title{
  margin-top: 57px;
  margin-bottom: 57px;
  position: relative;
  z-index: 1;
}
.window-done__img_lightning{
  position: absolute;
  top: -25px;
  left: 0;
  z-index: -1;
  transform: translateX(-30%);
  -webkit-transform: translateX(-30%);
  -moz-transform: translateX(-30%);
  -ms-transform: translateX(-30%);
  -o-transform: translateX(-30%);
}
.window-done__btn{
  margin: 0 auto;
  margin-bottom: 16px;
}
.window-done__img_cloud{
  position: absolute;
  top: 0;
  right: 0;
}
.window-done__img_clip{
  position: absolute;
  top: 33px;
  right: 29px;
} */



/* .modal__placed-quote */
/* =========================== */

.modal__placed-quote{
  padding:87px 37.5px 119px 37.5px ;
  border: 0;
  max-width: 296px;
  position: relative;
}
.placed-quote__title{
  position: relative;
  z-index: 1;
}
.placed-quote__img_star{
  position: absolute;
  z-index: -1;
  top: -40px;
  left: -20px;
}
/* .placed-quote__img_turquoise-hands{
  position: absolute;
  bottom: 0;
  right: 22px;
} */

.modal__placed-quote_dark{
  background: var(--placed-quote_dark-bg);
}
.modal__placed-quote_dark .placed-quote__title{
  color: var(--light-text);
}
.placed-quote__img_yellow-hands{
  position: absolute;
  bottom: 0;
  right: 22px;
}
.placed-quote__img_cercle{
 position: absolute;
 top: 0;
 left: 0;
}



/* .modal__reminder */
/* =========================== */
.reminder{
  max-width: 296px;
  background-color: var(--main-bg);
  padding: 78px 37px 28px 37px;
  position: relative;
  overflow: hidden;
  z-index: 0;
}
.reminder__title{
  margin-bottom: 8px;
  z-index: 1;
  position: relative;
}
.reminder__text{
  position: relative;
  color: var(--reminder--text);
  font-size: 14px;
  line-height: 136.4%;
  margin-bottom: 37px;
  z-index: 1;
}
.reminder__text span{
  color: var(--status-bg-two);
}
.reminder__img_lightning{
  position: absolute;
  top: -15px;
  left: -20px;
  z-index: -1;
}
.reminder__btn{
  margin: 0 auto;
  z-index: 1;

}
.reminder__img_cloud{
  position: absolute;
  top: 0;
  right: 0;

}
.reminder__img_jackdaw{
  position: absolute;
  top: 25px;
  right: 25px;
  z-index: 1;
}
/* .reminder__subscription {
}
.reminder__selection {
  margin-bottom: 10px;
  margin-left: -15px;
}
.reminder__selection label{
  color: var(--progress-bar-progress);
  font-size: 14px;
  line-height: 136.4%;
}
.reminder__selection:last-child{
  margin-bottom: 21px;
} */
/* #sms {
  opacity: 0;
}
#sms ~ label::before{
  content: ' ';
  display: inline-block;
  border:  1px solid var(--progress-bar-progress);
  width: 20px;
  height: 20px;
  background: transparent;
  transform: translateY(20%);
  -webkit-transform: translateY(20%);
  -moz-transform: translateY(20%);
  -ms-transform: translateY(20%);
  -o-transform: translateY(20%);
  margin-right: 15px;
  text-align: center;
  position: relative;
}
#sms:checked ~ label{
  position: relative;
}
#sms:checked ~ label::after {
  content: '\2713';
  position: absolute;
  left: 5px;
  top: 1px;
  font-size: 14px;
}
#calendar {
  opacity: 0;
}
#calendar ~ label::before{
  content: '';
  display: inline-block;
  border:  1px solid var(--progress-bar-progress);
  width: 20px;
  height: 20px;
  background: transparent;
  transform: translateY(20%);
  -webkit-transform: translateY(20%);
  -moz-transform: translateY(20%);
  -ms-transform: translateY(20%);
  -o-transform: translateY(20%);
  margin-right: 15px;
  text-align: center;
}
#calendar:checked ~ label{
  position: relative;
}
#calendar:checked ~ label::after {
  content: '\2713';
  position: absolute;
  left: 5px;
  top: 0px;
  font-size: 14px;
} */


/*
.reminder_min{
  border: 0;
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  max-width: 295px;
  min-width: 295px;
  padding: 52px 16px 42px;
}

.reminder_min .reminder__selection:last-child{
  margin-bottom: 27px;
}

#sms-min{
  opacity: 0;
}
#sms-min ~ label::before{
  content: ' ';
  display: inline-block;
  border:  1px solid var(--progress-bar-progress);
  width: 20px;
  height: 20px;
  background: transparent;
  transform: translateY(20%);
  -webkit-transform: translateY(20%);
  -moz-transform: translateY(20%);
  -ms-transform: translateY(20%);
  -o-transform: translateY(20%);
  margin-right: 15px;
  text-align: center;
  position: relative;
}
#sms-min:checked ~ label{
  position: relative;
}
#sms-min:checked ~ label::after{
  content: '\2713';
  position: absolute;
  left: 5px;
  top: 1px;
  font-size: 14px;
}
#calendar-min {
  opacity: 0;
}
#calendar-min ~ label::before{
  content: '';
  display: inline-block;
  border:  1px solid var(--progress-bar-progress);
  width: 20px;
  height: 20px;
  background: transparent;
  transform: translateY(20%);
  -webkit-transform: translateY(20%);
  -moz-transform: translateY(20%);
  -ms-transform: translateY(20%);
  -o-transform: translateY(20%);
  margin-right: 15px;
  text-align: center;
}
#calendar-min:checked ~ label{
  position: relative;
}
#calendar-min:checked ~ label::after {
  content: '\2713';
  position: absolute;
  left: 5px;
  top: 0px;
  font-size: 14px;
} */

