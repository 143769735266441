.title {
  position: relative;
  z-index: 1;
}

.btn {
  margin: 0 auto;
  margin-bottom: 16px;
}

.content {
  padding: 87px 37.5px 119px 37.5px;
  border: 0;
  max-width: 296px;
  position: relative;
}

.starIcon {
  position: absolute;
  z-index: -1;
  top: -40px;
  left: -20px;
}

.handsIcon {
  position: absolute;
  bottom: 0;
  right: 22px;
}
