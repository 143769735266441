.main {
  flex: 1 1 auto;
  background: var(--header-bg);
  position: relative;
  height: 100vh;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
}

.main__decor {
  position: absolute;
  top: 100px;
  right: 0;

  :global(._rtl) & {
    right: initial;
    left: 0;
    transform: scaleX(-1);
  }
}
.main__body {
  padding: 40px 47px 102px 47px;
  margin: auto;
}
.main__quote {
  color: var(--light-text);
  font-weight: 300;
  font-size: 12px;
  line-height: 108.6%;
  margin-bottom: 26px;
}
.main__welcome {
  color: var(--accent-text);
  font-weight: 700;
  font-size: 25px;
  line-height: 108.6%;
  margin-bottom: 10px;
}
.main__text {
  color: var(--light-text);
  font-weight: 400;
  font-size: 14px;
  line-height: 108.6%;
}

.languageWrapper {
  color: var(--light-text);
}
