.body {
  display: block;
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.content {
  background: var(--main-bg);
  border-radius: 65px;
  -webkit-border-radius: 65px;
  -moz-border-radius: 65px;
  -ms-border-radius: 65px;
  -o-border-radius: 65px;
  border: 2px solid var(--second-color);
  overflow: hidden;
  max-width: 351px;
  min-width: 296px;
  padding: 27px 17px 39px;
}
