.root {
  margin-top: 1.5em;

  & .icon {
    color: inherit;
  }

  & .select {
    z-index: 999;
    position: relative;
    color: inherit;
  }

  & > svg {
    color: inherit;
  }

  & .select::before,
  & .select:hover::before {
    border-bottom: none !important;
  }
}
