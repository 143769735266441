.title {
  color: var(--second-color);
  font-weight: 700;
  font-size: 22px;
  line-height: 136.4%;
  margin-bottom: 30px;
}
.title span {
  color: var(--status-bg-two);
}
.text {
  color: var(--second-color);
  font-size: 18px;
  line-height: 136.4%;
  margin-bottom: 60px;
}
.text span {
  color: var(--status-bg-two);
}
.quote {
  color: var(--progress-bar-progress);
  font-size: 22px;
  line-height: 136.4%;
  text-align: center;
  max-width: 190px;
  margin: 0 auto;
}
