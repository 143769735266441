.title {
  margin-top: 25px;
  margin-bottom: 20px;
  position: relative;
  z-index: 1;
}
.imgLightning {
  position: absolute;
  top: -25px;
  left: 0;
  z-index: -1;
  transform: translateX(-30%);
  -webkit-transform: translateX(-30%);
  -moz-transform: translateX(-30%);
  -ms-transform: translateX(-30%);
  -o-transform: translateX(-30%);
}
.btn {
  margin: 0 auto;
  margin-bottom: 16px;
}
.imgCloud {
  position: absolute;
  top: 0;
  right: 0;
}
.imgClip {
  position: absolute;
  top: 33px;
  right: 29px;
}

.content {
  width: 296px;
  background-color: var(--main-bg);
  padding: 37px;
  position: relative;
  overflow: hidden;
}
.details {
  background: var(--details-bg);
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 17px;
}
.datetime {
  color: var(--date-text);
  /*font-size: 16px;*/
  line-height: 136.4%;
  text-align: center;
}
.separator {
  height: 1px;
  border-width: 0;
  color: var(--status-bg-two);
  margin-top: 10px;
  background-color: var(--status-bg-two);
}
