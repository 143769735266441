.title,
.datetime {
  margin-left: 13px;
}
.title {
  color: var(--accent-text);
  font-weight: 600;
  font-size: 14px;
  line-height: 136.4%;
  margin-bottom: 14px;
}
.datetime {
  color: var(--date-text);
  font-size: 16px;
  line-height: 136.4%;
  margin-bottom: 17px;
}
.datetime span {
  color: var(--third-color);
  font-weight: 600;
}
.details {
  background: var(--details-bg);
  padding: 34px 15px 56px;
  border-radius: 4px;
  margin-bottom: 27px;
}
