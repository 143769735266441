.taskBody {
  height: 100%;
  padding: 0.75em;
}

.disabled {
  pointer-events: none;
  opacity: 0.6;
}

.task {
  margin-bottom: 10px;
  padding: 12px;
  background: url(images/svg/the-note.svg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100%;
  overflow: hidden;
  position: relative;
  width: 140px;
  height: 130px;

  &.taken,
  &.in_progress {
    background: url(images/svg/the-note-taken.svg);
    background-repeat: no-repeat;
    background-position: center center;
  }

  :global(.to-do__week) & {
    margin: 0 auto;
    margin-bottom: 10px;
    width: 120px;
    height: 110px;
    padding: 5px;
  }
}
.task:last-child {
  margin-bottom: 0;
}

.status {
  color: var(--light-text);
  font-weight: 700;
  font-size: 9px;
  line-height: 136.4%;
  padding: 2px 4px;
  margin-bottom: 15px;
  background-color: var(--status-bg-one);
  border-radius: 13px;
  display: inline-block;
}

.status.busyLabel {
  background-color: var(--status-bg-two);
}

.time {
  padding-left: 10px;
  color: var(--main-text);
  font-weight: 700;
  font-size: 13px;
  line-height: 136.4%;
  text-decoration: underline;
  margin-bottom: 7px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.title {
  padding-left: 10px;
  color: var(--main-text);
  font-weight: 400;
  font-size: 13px;
  line-height: 136.4%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.clip {
  position: absolute;
  z-index: 2;
  top: 0;
  right: 0;
}
