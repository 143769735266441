.title {
  margin-bottom: 8px;
  z-index: 1;
  position: relative;
}
.imgLightning {
  position: absolute;
  top: -15px;
  left: -20px;
  z-index: -1;
}
.btn {
  margin: 0 auto;
  z-index: 1;
}
.imgCloud {
  position: absolute;
  top: 0;
  right: 0;
}
.jackdaw {
  position: absolute;
  top: 25px;
  right: 25px;
  z-index: 1;
}
.content {
  max-width: 296px;
  background-color: var(--main-bg);
  padding: 78px 37px 28px 37px;
  position: relative;
  overflow: initial;
  z-index: 0;
}
.text {
  position: relative;
  color: var(--reminder--text);
  font-size: 14px;
  line-height: 136.4%;
  margin-bottom: 37px;
  z-index: 1;
}
.text span {
  color: var(--status-bg-two);
}
.addToCalendar {
  margin-bottom: 10px;
}
