.languageWrapper {
  color: var(--second-color);
  margin-left: -0.5em;
  margin-right: -0.5em;
}
.root {
  /* min-width: 307px; */
  /* width: 307px; */
  overflow: hidden;
  padding: 40px 27px 53px 27px;
  background: var(--light-text);
  border-radius: 15px;
  position: relative;
}
.form {
  padding-top: 40px;
}
.title {
  color: var(--second-color);
  font-weight: 700;
  font-size: 20px;
  line-height: 136.4%;
  margin-bottom: 30px;
}
.title span {
  color: var(--status-bg-two);
}
.subtitle {
  color: var(--second-color);
  font-size: 16px;
  line-height: 136.4%;
}

.label {
  color: var(--second-color);
  font-size: 16px;
  line-height: 136.4%;
  display: block;
  margin-bottom: 15px;
}
.input {
  display: block;
  margin-bottom: 30px;
  background: var(--input-bg);
  min-height: 46px;
  min-width: 258px;
  padding: 10px 8px;
  color: var(--input-text);
  font-size: 14px;
}
.phoneInput {
  background: var(--input-bg);
  color: var(--input-text);
  font-size: 14px;
  margin-bottom: 30px;

  & input {
    background: var(--input-bg);
    color: var(--input-text);
    font-size: 14px;
  }

  & fieldset {
    border: none;
  }
}
.text {
  color: var(--progress-bar-progress);
  font-size: 12px;
  line-height: 136.4%;
  text-align: center;
  margin: 0 -10px;
  margin-bottom: 20px;
}
.text span {
  color: var(--accent-text);
}
.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--light-text);
  font-weight: 700;
  font-size: 20px;
  line-height: 136.4%;
  min-height: 60px;
  border-radius: 14px;
  background: var(--placed-quote_dark-bg);
}

.main__decor {
  position: absolute;
  top: 30px;
  right: 0;
  z-index: 1;

  :global(._rtl) & {
    position: absolute;
    right: auto;
    left: 0;
    z-index: 1;
    transform: rotateY(180deg);
  }
}

.errorAlert {
  background-color: var(--error);
  margin-bottom: 2em;
}
.action {
  color: var(--accent-text);
}
